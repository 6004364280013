<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Agregar asistente</h3>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12" style="padding-bottom: 1px">
            <v-text-field
              v-model="nombre"
              name="SG-nombre"
              label="Nombres"
              type="text"
              :loading="loading"
              color="blue-grey"
              outlined
              dense
              @input="buscarConsejero"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" style="padding-top: 1px">
            <v-card flat class="scroll">
              <div v-if="error" class="error-text">{{ error }}</div>
              <v-list>
                <v-list-item
                  v-for="(consejero, index) in encontrados"
                  :key="`encontrado-${index}`"
                >
                  <v-list-item-icon class="avatar-item">
                    <v-icon size="40">mdi-account-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      consejero.nombre
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      consejero.dependencia
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle >{{ consejero.categoria }} </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="consejero.calidad=='Suplente'" style="color:red">{{ consejero.calidad }} </v-list-item-subtitle>
                    <v-list-item-subtitle v-else style="color:green">{{ consejero.calidad }} </v-list-item-subtitle>

                    <h6 style="color:orange" v-if="consejero.correoNoEnviado"> {{consejero.correoNoEnviado}}</h6>

                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                    :loading="loading"
                      icon
                      outlined
                      color="green darken-1"
                      :disabled="consejero.asistente"
                      @click="agregarAsistencia(consejero._id)"
                      ><v-icon>mdi-check</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { agregarAsistenciaService } from "../administrar.service";

export default {
  name: "agregarAsistenteManual",
  props: ["mostrar", "idSesion", "consejeros", "asistencias"],
  computed: {
    ...mapGetters(["userAdmin"]),
    consejerosLocal() {
      const consejeros = this.consejeros.map((x) => {
        const consejero = {
          _id: x._id,
          propietario: {
            _id: x.usuarioMaster._id,
            nombre: x.usuarioMaster.nombre,
            categoria: x.categoria,
            dependencia: x.dependencia.nombre,
            calidad: "Propietario",
          },
          suplente: {
            _id:  x.suplente.usuarioMaster?x.suplente.usuarioMaster._id:'',
            nombre:  x.suplente.usuarioMaster?x.suplente.usuarioMaster.nombre:'',
            categoria: x.suplente.categoria,
            dependencia: x.dependencia.nombre,
            calidad: "Suplente",
          },
        };
        return consejero;
      });
      return consejeros;
    },
  },
  data() {
    return {
      loading: false,
      nombre: "",
      encontrados: [],
      agregado:false,
      error:null,
      // errorCorreo:null
    };
  },
  methods: {
    async buscarConsejero() {
      console.log(this.nombre);
      this.error=null
      this.agregado = false;
      this.encontrados = [];
      if (this.nombre.length > 2) {
        try {
          const regex = new RegExp(this.nombre, "i");
          this.consejerosLocal.forEach((consejero) => {
            if (regex.test(consejero.propietario.nombre))
              this.encontrados.push({...consejero.propietario, asistente:false, correoNoEnviado: null});
            if (regex.test(consejero.suplente.nombre))
              this.encontrados.push({...consejero.suplente,asistente:false, correoNoEnviado: null});
          });
          this.encontrados.forEach(encontrado => {
              if(this.asistencias.findIndex(x=>x.usuarioMaster == encontrado._id) >= 0){
                encontrado.correoNoEnviado = null
                encontrado.asistente = true;
              }
          });
         
        } catch (error) {
          this.encontrados = [];
        }
      }
    },
    async agregarAsistencia(idUsuario) {
      try {
        this.loading=true
        const sendData = { idSesion: this.idSesion, usuarioMaster: idUsuario };
        const serverResponse = await agregarAsistenciaService(sendData);
        this.loading = false;
        if (!serverResponse.ok) {
         
          this.error = serverResponse.mensaje;
          throw new Error(serverResponse.mensaje);
        } else {
          // this.errorCorreo = serverResponse.correoFallado
          const emitData = { asistencia: serverResponse.asistencia };
         
          this.$emit('asistenciaAgregada', emitData);


          let index = this.encontrados.findIndex(x=>x._id==serverResponse.asistencia.usuarioMaster)
          this.encontrados[index].asistente=true;
          if(serverResponse.correoFallado) this.encontrados[index].correoNoEnviado= serverResponse.correoFallado  
          else    this.encontrados[index].correoNoEnviado=null
          this.$notificarSuccess('Asistencia capturada correctamente')
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>

<style>
.avatar-item {
  margin-right: 15px !important;
}
</style>