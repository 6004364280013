import { 
    getServerRequest, 
    postServerRequest, 
    putServerRequest,
    patchServerRequest
} from '../../helpers/serverRequest';
import { store } from '../../store/store';

const httpURL = store.getters.httpURL;

export async function getConsejosService() {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/consejos`;
    return await getServerRequest(url, config);
}

export async function getConsejoService(idConsejo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/consejo/${idConsejo}`;
    return await getServerRequest(url, config);
}

export async function agregarConsejoService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/consejos/consejo`;
    return await putServerRequest(url, data, config);
}

export async function getUsusarioByCodigo(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios/master`;
    return await postServerRequest(url, data, config);
}

export async function setUsuarioNuevoMaster(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios/registrarNuevo`;
    return await postServerRequest(url, data, config);
}

export async function agregarConsejeroService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/consejos/consejero/propietario`;
    return await putServerRequest(url, data, config);
}

export async function subirCSV(file) {
    const token = store.getters.sessionToken;
    const config = { headers: { token, "Content-Type": "multipart/form-data" } };
    const url = `${httpURL}/consejos/consejero/csv`;
    return await postServerRequest(url, file, config);
}

export async function modificarConsejeroService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/consejos/consejero/${data.tipo}/${data.idConsejero}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarDatosService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/consejos/consejeroData/${data.idConsejero}`;
    return await patchServerRequest(url, data, config);
}

export async function eliminarSuplenteService(idConsejero) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/consejos/consejeroEliminar/${idConsejero}`;
    return await patchServerRequest(url, {}, config);
}

export async function modificarCorreoService(query,idConsejo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/consejos/correo/${idConsejo}`;
    return await patchServerRequest(url, {query}, config);
}

export async function getConsejeroService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/${data.idSesion}/consejero/${data.usuarioMaster}`;
    return await getServerRequest(url, config);
}

export async function agregarSuplenteCSV(data,consejo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/consejos/csv/suplente/${consejo}`;
    return await patchServerRequest(url, data, config);
}
